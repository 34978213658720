import { type JSX, useEffect, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useDispatch, useSelector } from 'react-redux';
import { components, GroupHeadingProps, MenuPosition } from 'react-select';
import { racClauseSelectors } from '@gonfalon/dogfood-flags';
import { CustomSelect, OptionProps, StylesObject } from '@gonfalon/launchpad-experimental';
import { useParam } from '@gonfalon/router';
import {
  Collection,
  ComboBox,
  Group,
  Header,
  IconButton,
  Input,
  Label,
  ListBox,
  ListBoxItem,
  ListBoxSection,
  Popover,
  ProgressBar,
  Text,
} from '@launchpad-ui/components';
import { List } from 'immutable';
import { Label as LegacyLabel, Tooltip as LegacyTooltip } from 'launchpad';
import { v4 } from 'uuid';

import { fetchContextKindsForProject as fetchContextKindsForProjectAction } from 'actions/contextKinds';
import { ContextKind } from 'components/Contexts/types';
import { makeSelectContextKindOptions, StrictGroupedOption } from 'components/Contexts/utils/contextTargetingUtils';
import { useFlagContext } from 'components/FlagContext';
import { FlagContextErrorWrapper } from 'components/FlagContextErrorWrapper';
import { contextKindsRequestSelector, unhiddenContextKindsSelector } from 'reducers/contextKinds';
import { currentProjectKeySelector } from 'reducers/projects';
import { CONTEXT_KIND_ATTRIBUTE } from 'utils/constants';
import { ready } from 'utils/reduxUtils';

import styles from './SelectContextKind.module.css';

export type SelectContextKindProps = {
  className?: string;
  id?: string;
  value?: string;
  projKey?: string;
  flagKey?: string;
  name?: string;
  disabled?: boolean;
  providedContextKinds?: ContextKind[];
  shouldFetchContextKinds?: boolean;
  label?: string | JSX.Element;
  menuPosition?: MenuPosition;
  onChange?(contextKind: string): void;
  /**
   * @deprecated New RAC-based selector does not use this prop
   */
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
};

export const useRedux = (requestedProjKey?: string) => {
  const currentProjKey = useSelector(currentProjectKeySelector);
  const projKey = requestedProjKey || currentProjKey;
  const contextKinds = useSelector(unhiddenContextKindsSelector);
  const contextKindsRequest = useSelector(contextKindsRequestSelector);

  const dispatch = useDispatch();
  const fetchContextKinds = () => dispatch(fetchContextKindsForProjectAction(projKey));

  const isReady = ready(contextKindsRequest);

  return {
    isReady,
    contextKinds,
    fetchContextKinds,
  };
};

const customSelectStyleOverrides: StylesObject = {
  input: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  group: {
    paddingBottom: '0',
    paddingTop: '0',
  },
  groupHeading: {
    backgroundColor: 'var(--lp-color-bg-ui-secondary)',
    color: 'var(--lp-color-text-ui-secondary)',
    fontSize: 'var(--lp-font-size-200)',
    letterSpacing: '1px',
    textTransform: 'none',
    paddingTop: 'var(--lp-spacing-300)',
    paddingBottom: 'var(--lp-spacing-300)',
    borderTop: '1px solid var(--lp-color-border-ui-primary)',
  },
};

const GroupOptionHeading = (props: GroupHeadingProps<StrictGroupedOption>) => {
  if (!props.data.label) {
    return <components.GroupHeading style={{ display: 'none' }} {...props} />;
  } else {
    return <components.GroupHeading {...props} />;
  }
};

const SelectContextKind = ({
  className,
  id = v4(),
  value: selectedContextKindValue,
  name,
  projKey,
  flagKey,
  disabled,
  providedContextKinds,
  shouldFetchContextKinds = true,
  menuPosition,
  label,
  onBlur,
  onChange,
}: SelectContextKindProps) => {
  const { isReady, contextKinds, fetchContextKinds } = useRedux(projKey);
  const didFirstRender = useRef(false);

  useEffect(() => {
    if (shouldFetchContextKinds && !isReady) {
      fetchContextKinds();
    }
    didFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (shouldFetchContextKinds && didFirstRender.current) {
      fetchContextKinds();
    }
  }, [projKey]);

  const { flagConfiguration } = useFlagContext();
  const contextKindsToDisplay = shouldFetchContextKinds ? contextKinds : (providedContextKinds ?? []);
  const flagEvaluatedContextKindKeys = (flagKey && flagConfiguration?.evaluation?.contextKinds) || List<string>();

  const optionsResult = makeSelectContextKindOptions({
    contextKinds: contextKindsToDisplay,
    evaluatedContextKindKeys: flagEvaluatedContextKindKeys,
  });

  const flattedOptions = optionsResult.groupedOptions
    ? optionsResult.options.reduce<Array<{ value: string; label: string }>>(
        (accum, currStrictGroupedOption) => [...accum, ...currStrictGroupedOption.options],
        [],
      )
    : optionsResult.options;
  let selectedOption: { label: string; value: string } | undefined;

  if (selectedContextKindValue) {
    selectedOption = flattedOptions.find((o) => o.value === selectedContextKindValue) || {
      label: selectedContextKindValue,
      value: selectedContextKindValue,
    };
  }

  const renderItem = (item: { value: string; label: string }) => (
    <ListBoxItem key={item.value} id={item.value} value={item} textValue={item.label}>
      <Text slot="label">{item.label}</Text>
      {item.value === CONTEXT_KIND_ATTRIBUTE ? <Text slot="description">Option for matching context kinds</Text> : null}
    </ListBoxItem>
  );

  const renderGroup = (groupedOption: StrictGroupedOption) => (
    <ListBoxSection
      key={groupedOption.label}
      id={groupedOption.label}
      items={groupedOption.options}
      aria-label={groupedOption.label}
    >
      <Header>{groupedOption.label}</Header>
      <Collection items={groupedOption.options}>{renderItem}</Collection>
    </ListBoxSection>
  );

  // I couldn't figure out how to make ListBox accept a generic union of props (items + matching children render function)
  const listbox = optionsResult.groupedOptions ? (
    <ListBox
      aria-label="Context Kinds"
      renderEmptyState={() => (isReady ? 'No values found' : 'Loading values')}
      items={optionsResult.options}
      children={renderGroup}
    />
  ) : (
    <ListBox
      aria-label="Context Kinds"
      renderEmptyState={() => (isReady ? 'No values found' : 'Loading values')}
      items={optionsResult.options}
      children={renderItem}
    />
  );

  if (racClauseSelectors()) {
    return (
      <>
        <ComboBox
          id={id}
          name={name}
          className={className}
          isDisabled={disabled}
          onSelectionChange={(value) => {
            // Ignore nulls and numbers (numbers dont exist but TS thinks they do)
            if (typeof value === 'string') {
              onChange?.(value);
            }
          }}
          selectedKey={selectedOption?.value}
          aria-label="Select a context kind"
        >
          <Label>Context kind</Label>
          <Group>
            <Input aria-label="Select context kind" placeholder="Select a context kind" />
            {!isReady ? <ProgressBar isIndeterminate aria-label="Loading context kinds" /> : null}
            <IconButton icon="chevron-down" size="small" variant="minimal" aria-label="Pick context kind" />
          </Group>
          <Popover placement="bottom" aria-label="Select a context kind">
            {listbox}
          </Popover>
        </ComboBox>
      </>
    );
  }
  return (
    <>
      {label ? <LegacyLabel htmlFor={id}>{label}</LegacyLabel> : null}
      <CustomSelect
        className={className}
        id={id}
        name={name}
        value={selectedOption}
        options={optionsResult.options}
        onChange={(option: OptionProps) => onChange?.(option.value)}
        onBlur={onBlur}
        placeholder="Select a context kind"
        ariaLabel="Select context kind"
        styles={customSelectStyleOverrides}
        noOptionsMessage={() => (!isReady ? 'Loading values' : 'No values found')}
        isLoading={!isReady}
        disabled={disabled}
        formatOptionLabel={formatOptionLabel}
        customComponents={{ GroupHeading: GroupOptionHeading }}
        menuPosition={menuPosition}
      />
    </>
  );
};

const formatOptionLabel = (option: OptionProps) => {
  const optionLabel = <div className="SelectContextKind-option">{option.label}</div>;

  if (option.value !== CONTEXT_KIND_ATTRIBUTE) {
    return optionLabel;
  }

  return (
    <LegacyTooltip targetClassName={styles.tooltip} placement="top" content="Option for matching context kinds">
      {optionLabel}
    </LegacyTooltip>
  );
};

type SelectContextKindWrapperProps = Omit<SelectContextKindProps, 'flagKey'>;

/* eslint-disable import/no-default-export */
export default (props: SelectContextKindWrapperProps) => {
  const params = {
    envKey: useParam('envKey', { optional: true }),
    flagKey: useParam('flagKey', { optional: true }),
  };

  const ContextKindsModal = <SelectContextKind {...props} flagKey={params.flagKey} />;

  return params.flagKey && params.envKey ? (
    <FlagContextErrorWrapper envKey={params.envKey} flagKey={params.flagKey} expandEvaluation>
      {ContextKindsModal}
    </FlagContextErrorWrapper>
  ) : (
    ContextKindsModal
  );
};
